$box-shadow-sm: 0 1px 3px rgba(0,0,0,0.12),
                0 1px 2px rgba(0,0,0,0.24);

$box-shadow-md: 0 4px 8px rgba(0,0,0,0.18),
                0 4px 7px rgba(0,0,0,0.32);

$box-shadow-lg: 0 8px 16px rgba(0,0,0,0.22),
                0 8px 15px rgba(0,0,0,0.48);

$easing: all 0.3s cubic-bezier(.25, .8, .25, 1);

$primary-color: #140745;
$primary-bg-color: #d6d6d6;
$primary-bg-color-hover:#e8e8e8;

$font-base: 'Badaboom', sans-serif;
$font-monospace: "SF Mono", "Monaco", "Inconsolata", "Fira Mono", "Droid Sans Mono", "Source Code Pro", monospace;
