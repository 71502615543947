@import 'src/assets/styles/abstracts/variables';

.button {
  color: #ff0;
  cursor: pointer;
  border-radius: 4px;
  font-family: $font-base;
  letter-spacing: 0.065em;
  background: #140745;
  box-shadow: $box-shadow-sm;
  transition: $easing;

  &__icon {
    font-style: normal;
  }

  &__text {
    margin: 0 0 0 10px;
    white-space: nowrap;
  }

  &:hover {
    background: #200e5f;
  }
}
