@import 'src/assets/styles/abstracts/mixins';
@import 'src/assets/styles/abstracts/variables';

.items-filters {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -5px;
}

.items-filter {
  flex: 1;
  height: 40px;
  margin: 5px;
  padding: 0 10px;
  border-radius: 4px;
  background: $primary-bg-color;
  border: 1px solid $primary-color;

  &--input {
    min-width: 250px;
  }

  &--select {
    min-width: 150px;
  }

  input,
  select {
    border: 0;
    outline: 0;
    width: 100%;
    height: 100%;
    font-size: 12px;
    background: $primary-bg-color;
  }

  select {
    cursor: pointer;
  }
}

.items-body {
  margin: 10px 0 0;
}
