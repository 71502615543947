.hero-stats {
  font-size: 13px;
  font-weight: 600;

  .emoji-icon {
    width: 22px;
    height: 22px;
  }

  li {
    margin: 4px 0 0;
  }
}
