.app-container {
  padding: 0 20px;
  margin: auto;
  max-width: 1140px;
}

.main {
  position: relative;
  margin: 15px 0;
}
