@import 'src/assets/styles/abstracts/mixins';
@import 'src/assets/styles/abstracts/variables';

.header {
  width: 100%;
  margin-top: 25px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  &__logo {
    padding: 10px;
    margin: 0 auto;
    min-width: 128px;
    max-width: 128px;
    height: 128px;
    border-radius: 8px;
    background: #140745;
    box-shadow: $box-shadow-sm;

    img {
      display: block;
      max-width: 100%;
    }
  }

  &__menu {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 15px 0 0;
  }

  &__menu-item {
    flex: 1;
    margin: 5px;

    &--lang {
      max-width: 50px;
    }

    button {
      width: 100%;
      height: 50px;
      padding: 0 15px;
      font-size: 18px;
    }

    button.button-lang {
      padding: 0;
      min-width: 50px;
      height: 50px;
    }
  }
}
