$tablet-width: 768px;
$desktop-width: 1024px;

@mixin mobile {
  @media (max-width: #{$tablet-width - 1px}) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: #{$tablet-width}) and (max-width: #{$desktop-width - 1px}) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: #{$desktop-width}) {
    @content;
  }
}

@mixin card {
  width: 100%;
  height: 100%;
  border-radius: 4px;
  box-shadow: $box-shadow-sm;
  background: $primary-bg-color;
}

@mixin title {
  font-size: 13px;
  line-height: 1.25;
  font-weight: 600;
}
