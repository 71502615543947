.emoji-icon {
  display: inline-block;
  padding: 4px 0 0 0;
  width: 20px;
  height: 20px;
  text-align: center;

  &--dark {
    background: #000;
  }
}
